import React from "react";
import { Box } from "@material-ui/core";
import CorporateSignature from "my-hr/components/my-hr-applications-data-panel/components/corporate-signature";
import ApplicationCheckBox from "my-hr/components/my-hr-applications-data-panel/components/application-check-box";
import useStyles from "./first-marriage-tax-credit-application-print.style";
import ApplicationPrintCardInfoItem from "my-hr/components/my-hr-applications-data-panel/components/application-print-card-info-item";
import { DOTTED_LINE } from "my-hr/components/my-hr-applications-data-panel/constants";
import formatTaxNumber from "oc/utils/format-tax-number";

export const FirstMarriageTaxCreditApplicationPrint = React.forwardRef(
  (props, ref) => {
    const {
      fullName,
      taxNumber,
      applicationDate,
      isModifiedApplication,
      //marriageDiscountContract,
      marriageMounth,
      marriageAmount,
      noneMarriageDiscountContract,
      states,
      employerName,
      employerTaxNumber,
      marriageDateDate,
    } = props;
    const spouseName = states?.spouseName || "";
    const spouseTaxNumber = states?.spouseTaxNumber || "";
    const companyName = states?.spouseEmployerName || "";
    //const marriageDateDate =
    //  new Date(states?.marriageDate).toLocaleDateString() || "";
    const companyTaxNumber = formatTaxNumber(states?.spouseEmployerTaxNumber) || "";
    let year = applicationDate?.slice(0, 4);
    const classes = useStyles();

    return (
      <Box ref={ref}>
        <Box padding="0px 40px" fontFamily="EB Garamond">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{
              paddingTop: "20px",
            }}
          >
            <Box
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Adóelőleg-nyilatkozat az első házasok kedvezményének
              érvényesítéséről
              <Box />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginBottom="20px"
              >
                A nyilatkozat benyújtásának éve: {year}
              </Box>
            </Box>
          </Box>
          <Box className={classes.blackBorder}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                style={{
                  fontWeight: "bold",
                }}
              >
                I.
                <Box
                  style={{
                    fontWeight: "normal",
                    marginLeft: "15px",
                  }}
                >
                  A nyilatkozatot adó magánszemély
                </Box>
              </Box>
              <Box
                style={{
                  fontWeight: "normal",
                }}
              >
                <Box display="flex" alignItems="center">
                  Módosított nyilatkozat:
                  <ApplicationCheckBox value={isModifiedApplication} />
                </Box>
              </Box>
            </Box>
            <Box marginLeft="28px">
              <Box height="15px" />
              <ApplicationPrintCardInfoItem label="neve:" value={fullName} />
              <Box height="15px" />
              <ApplicationPrintCardInfoItem
                label="adóazonosító jele:"
                value={taxNumber}
              />
            </Box>
            <Box height="15px" />
            <Box display="flex" alignItems="center">
              <Box
                style={{
                  fontWeight: "bold",
                }}
              >
                1.
              </Box>
              <Box marginLeft="13px">
                <ApplicationPrintCardInfoItem
                  label="Házastársam neve:"
                  value={spouseName ? spouseName : DOTTED_LINE}
                />
              </Box>
            </Box>
            <Box height="15px" />
            <Box marginLeft="28px">
              <ApplicationPrintCardInfoItem
                label="adóazonosító jele:"
                value={spouseTaxNumber ? spouseTaxNumber : DOTTED_LINE}
              />
            </Box>
            <Box height="15px" />
            <ApplicationPrintCardInfoItem
              label="Adóelőleget megállapító munkáltató, kifizető megnevezése:"
              value={companyName ? companyName : DOTTED_LINE}
            />
            <Box height="15px" />
            <Box marginLeft="295px">
              <ApplicationPrintCardInfoItem
                label="adószáma:"
                value={companyTaxNumber ? companyTaxNumber : DOTTED_LINE}
              />
            </Box>
            <Box height="15px" />
            <Box>
              A házasságkötés ideje, vagy a 25 év alatti fiatalok házasságkötése
              esetén a házastárs 25. életéve betöltésének
            </Box>
            <ApplicationPrintCardInfoItem
              label="időpontja:"
              value={
                marriageDateDate === "Invalid Date" ||
                  marriageDateDate?.length === 0
                  ? DOTTED_LINE
                  : new Date(marriageDateDate).toLocaleDateString()
              }
            />
            <Box height="15px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              2.
              <Box
                marginLeft="15px"
                fontWeight="normal"
                display="flex"
                lineHeight="1.5"
                alignItems="center"
                flexDirection="column"
                marginBottom="-4px"
              >
                Tudomásul veszem, hogy az első házasok kedvezménye maximum 24
                jogosultsági hónapon keresztül vehető figyelembe.
              </Box>
            </Box>
            <Box display="flex">
              <Box
                marginLeft="27px"
                fontWeight="normal"
                display="flex"
                lineHeight="1.5"
                alignItems="center"
                marginBottom="-4px"
                marginTop="2px"
              >
                2.1. Nyilatkozatomat az adóévben, legfeljebb a jogosultsági
                időszak végéig kérem figyelembe venni.
                <ApplicationCheckBox value={states?.applyInTaxYear} />
              </Box>
            </Box>
            <Box display="flex">
              <Box
                marginLeft="27px"
                fontWeight="normal"
                display="flex"
                lineHeight="1.5"
                alignItems="center"
                marginBottom="-4px"
                marginTop="2px"
              >
                2.2. Nyilatkozatomat visszavonásig, legfeljebb a jogosultsági
                időszak végéig kérem figyelembe venni
              </Box>
            </Box>
            <Box
              marginLeft="53px"
              fontWeight="normal"
              display="flex"
              lineHeight="1.5"
              alignItems="center"
              marginBottom="-4px"
              marginTop="2px"
            >
              (folytatólagos nyilatkozatot teszek).
              <ApplicationCheckBox value={states?.applyToWithdrawal} />
            </Box>
            <Box height="15px" />
            <Box display="flex">
              <Box
                style={{
                  fontWeight: "bold",
                }}
              >
                3.
              </Box>
              <Box marginLeft="15px" display="flex">
                Nyilatkozom, hogy {marriageMounth ? marriageMounth : "....."}{" "}
                hónaptól kezdődően (e hónapra is) az első házasok kedvezményét{" "}
                {marriageAmount ? marriageAmount : "....."} forint összegben
                kívánom igénybe venni.
              </Box>
            </Box>
            <Box height="15px" />
            <Box display="flex">
              <Box
                style={{
                  fontWeight: "bold",
                }}
              >
                4.
              </Box>

              <Box
                marginLeft="15px"
                display="flex"
                lineHeight="1.5"
                alignItems="flex-start"
                flexDirection="column"
                marginBottom="-4px"
              >
                A nyilatkozattétel időpontjától kezdődően (erre a hónapra sem)
                az első házasok kedvezményét nem
              </Box>
            </Box>
            <Box display="flex">
              <Box
                marginLeft="27px"
                display="flex"
                lineHeight="1.5"
                alignItems="center"
                marginBottom="-4px"
              >
                kívánom igénybe venni.
                <ApplicationCheckBox
                  value={noneMarriageDiscountContract}
                />{" "}
              </Box>
            </Box>
            <Box height="15px" />
            <Box display="flex">
              Felelősségem tudatában kijelentem, hogy a nyilatkozat tartalmát
              házastársam megismerte és elfogadta.
            </Box>
            <Box marginLeft="28px">
              <Box height="15px" />
              <Box display="flex" justifyContent="space-between">
                A nyilatkozatot tevő magánszemély aláírása:
                <Box marginRight="28px">
                  ................................................................
                </Box>
              </Box>
            </Box>
            <Box marginLeft="28px">
              <Box height="15px" />
              <Box display="flex">
                Kelt:
                <Box marginLeft="10px">{applicationDate}</Box>
              </Box>
            </Box>
          </Box>
          <Box height="15px" />
          <Box className={classes.blackBorder}>
            <Box display="flex">
              <Box
                style={{
                  fontWeight: "bold",
                }}
              >
                II.
              </Box>
              <Box
                style={{
                  fontWeight: "normal",
                  marginLeft: "15px",
                }}
              >
                Az I. Blokkban szereplő magánszemély munkáltatójaként
                (kifizetőjeként) a nyilatkozat tartalmát tudomásul vettem. A
                magánszemély adóelőlegét az I. Blokkban szereplő nyilatkozat
                figyelembevételével állapítom meg.
              </Box>
            </Box>
            <Box height="15px" />
            <ApplicationPrintCardInfoItem
              label="A munkáltató, kifizető megnevezése:"
              value={employerName ? employerName : DOTTED_LINE}
            />
            <Box height="15px" />
            <ApplicationPrintCardInfoItem
              label="A munkáltató, kifizető adószáma:"
              value={employerTaxNumber ? formatTaxNumber(employerTaxNumber) : DOTTED_LINE}
            />
            <Box height="15px" />
            <CorporateSignature orientation="horizontal" />
          </Box>
        </Box>
      </Box>
    );
  }
);

export default FirstMarriageTaxCreditApplicationPrint;
