import React from "react";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import CorporateSignature from "my-hr/components/my-hr-applications-data-panel/components/corporate-signature";
import { DOTTED_LINE } from "my-hr/components/my-hr-applications-data-panel/constants";
import useStyles from "my-hr/components/my-hr-applications-data-panel/components/family-tax-credit-application-print/family-tax-credit-application-print.style";
import formatTaxNumber from "oc/utils/format-tax-number";

export default function TaxCreditApplicationPrintEmployer({
  employerName,
  employerTaxNumber,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.blackBorder}>
      <Box>
        <Box display="flex">
          <Typography
            className={classes.formatText}
            style={{
              fontWeight: "bold",
              marginRight: "8px",
            }}
          >
            II.
          </Typography>
          <Typography className={classes.formatText}>
            Az I. Blokkban szereplő magánszemély munkáltatója/ kifizetője
          </Typography>
        </Box>
      </Box>
      <Box height="10px" />
      <Box display="flex">
        megnevezése:
        <Box width="500px" textAlign="center">
          {employerName ? employerName : DOTTED_LINE}
        </Box>
        <Box>adószáma:</Box>
        <Box marginLeft="10px">
          {employerTaxNumber ? formatTaxNumber(employerTaxNumber) : DOTTED_LINE}
        </Box>
      </Box>
      <Box height="10px" />
      <Typography className={classes.formatText}>
        A nyilatkozat tartalmát tudomásul vettem. A magánszemély adóelőlegét az
        I. Blokkban szereplő nyilatkozat figyelembevételével állapítom meg.
      </Typography>
      <Box height="20px" />
      <CorporateSignature orientation="vertical" />
    </Box>
  );
}
