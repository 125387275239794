import { useQuery } from "@apollo/client";
import { GET_SPOUSES } from "graphql/queries";
import useCurrentUserState from "hooks/use-current-user-state";
import usePolling from "hooks/use-polling";

const useSpousesData = () => {
  let { employeeCode } = useCurrentUserState();
  const { data, loading, error } = useQuery(GET_SPOUSES, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    variables: {
      employeeCode,
    },
  });
  const result = data?.spouses || [];
  return {
    data: result,
    loading,
    error,
  };
};

export default useSpousesData;
