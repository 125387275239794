import React from "react";
import { Box, Typography } from "@material-ui/core";
import OcPrivateLayoutFooter from "oc/components/oc-private-layout-footer";
import useStyles from "../family-tax-credit-information/family-tax-credit-information.style";

const MyPersonalTaxCreditInformation = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box padding="16px" style={{ backgroundColor: "#F1F3F4" }}>
        <Box>
          <Typography
            variant="h4"
            style={{
              fontSize: "34px",
              fontWeight: "400",
              color: "#5E6366",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Tájékoztató
          </Typography>
          <Box height="32px" />
          <Typography
            variant="h5"
            style={{
              fontSize: "24px",
              fontWeight: "400",
              color: "#212121",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Adóelőleg-nyilatkozat a személyi kedvezmény érvényesítéséről
          </Typography>
          <Box height="32px" />
          <Box className={classes.inlineFlexBoxs}>
            <Box className={classes.bgBox}>
              <Typography variant="h5" className={classes.titles}>
                <b>Tudnivalók a nyilatkozathoz</b>
              </Typography>
              <Box height="32px" />
              <Typography variant="h6">
                <b>Kinek kell átadni ezt a nyilatkozatot?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ahhoz, hogy munkáltatója, kifizetője az Ön járandóságaiból a
                személyi kedvezmény figyelembevételével vonja le az adóelőleget,
                <b>
                  {" "}
                  ezt a nyilatkozatot két példányban töltse ki és adja át
                  munkáltatójának, kifizetőjének.
                </b>{" "}
                A nyilatkozat egyik példányát a munkáltatónak (kifizetőnek),
                másik példányát pedig{" "}
                <b>
                  Önnek kell a bevallás benyújtásának évét követő 5. év végéig
                  megőriznie.
                </b>
              </Typography>
              <Box height="10px" />
              <Typography variant="body1">
                A munkáltató, kifizető a nyilatkozatot az átadást követő
                kifizetéseknél veszi figyelembe az adóéven belül, vagy kérésére
                folyamatosan.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>
                  Az adóelőleg-nyilatkozatot a munkáltatónak és olyan
                  kifizetőnek is adható, aki összevonás alá eső, rendszeres
                  jövedelmet
                </b>{" "}
                juttat Önnek, például megbízási szerződés alapján.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A kedvezményt az adóelőleg-nyilatkozat visszavonásáig folyamatosan figyelembe veheti a munkáltató, rendszeres bevételt
                juttató kifizető (folytatólagos nyilatkozat), ezt a 6. pontban
                kell jelezni. Ekkor a következő évben, években nem kell új
                nyilatkozatot adni.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <i>
                  Ha Ön a személyi kedvezményt jogalap nélkül veszi igénybe, és
                  ezért az adóbevallásában 10 ezer forintot meghaladó befizetési
                  kötelezettsége, vagyis adóhátraléka keletkezik, akkor ennek 12
                  százalékát különbözeti bírságként kell megfizetnie az
                  adóhátralékkal együtt.
                </i>
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Kinek jár a kedvezmény?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A személyi kedvezményt{" "}
                <b>a súlyos fogyatékosságról szóló orvosi igazolás</b> vagy a
                rokkantsági járadékra, fogyatékossági támogatásra való
                jogosultságról szóló <b>határozat alapján</b> lehet igénybe
                venni.
              </Typography>
              <Typography variant="body1">
                Az orvosi igazolást, határozatot a nyilatkozathoz nem kell
                csatolni, de az igazolást az elévülési időn belül meg kell
                őrizni. Orvosi igazolás nélkül veheti igénybe a személyi
                kedvezményt az, aki rokkantsági járadékban, vagy fogyatékossági
                támogatásban részesül (1.).
              </Typography>
              <Typography variant="body1">
                Súlyosan fogyatékos személynek számít, aki
              </Typography>
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  a{" "}
                  <i>
                    súlyos fogyatékosságnak minősülő betegségekről szóló
                    335/2009. (XII. 29.) Korm. rendeletben
                  </i>{" "}
                  felsorolt betegségek valamelyikében szenved, továbbá{" "}
                </li>
                <li>aki rokkantsági járadékban vagy</li>
                <li>fogyatékossági támogatásban részesül.</li>
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Mennyi kedvezmény jár?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A nyilatkozat alapján a munkáltató (kifizető) az adóelőleg
                alapját csökkenti jogosultsági hónaponként a minimálbér
                egyharmadának száz forintra kerekített összegével, azaz 2025-ben
                havi 96 900 (2.) forinttal.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Milyen jövedelmet érint?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A kedvezmény az összevont adóalapba tartozó jövedelmekre vehető
                igénybe, mint például a munkabér, adóköteles
                társadalombiztosítási ellátás, a gyermekgondozási díj, más havi,
                heti munkadíj, tiszteletdíj, személyes közreműködés ellenértéke,
                bérbeadásból származó jövedelem, egyéb juttatás.{" "}
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Hogyan érvényesülhet együtt több szja-kedvezmény?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A kedvezmények érvényesítésének sorrendje:
              </Typography>
              <Typography variant="body1">
                A személyi kedvezményt adóalap-csökkentő kedvezményként lehet
                igénybe venni a négy vagy több gyermeket nevelő anyák
                kedvezménye, a 25 év alatti fiatalok kedvezménye, 30 év alatti
                anyák kedvezménye <b>után</b> és az első házasok kedvezménye,
                illetve a családi kedvezmény <b>előtt.</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Adatváltozáskor mit kell tenni?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha a nyilatkozatban közölt adatok változnak,{" "}
                <b>Ön köteles haladéktalanul új nyilatkozatot tenni</b> – ezt a
                nyilatkozat jobb felső részében,{" "}
                <b>a Módosított nyilatkozat kódkockában</b> kell X-szel jelölni.{" "}
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha a továbbiakban nem kívánja igénybe venni a személyi
                kedvezményt, (például azért, mert a kedvezményre már nem
                jogosult, vagy év végén a bevallásában kívánja azt
                érvényesíteni) akkor ezt az 7. sorban kell jelölni. Ha év közben
                új munkáltatónál helyezkedik el és új nyilatkozatot tesz, az{" "}
                <b> nem számít módosított nyilatkozatnak.</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A kedvezményről bővebb tájékoztatást olvashat a NAV honlapján
                (www.nav.gov.hu) az „Szja adóalap-kedvezmények” című 73. számú
                információs füzetben.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>
                  Külföldi magánszemély milyen feltételek mellett érvényesítheti
                  a kedvezményt?
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Önnek csak akkor jár a személyi kedvezmény, ha azonos vagy
                hasonló <b>kedvezményt</b> ugyanarra az időszakra{" "}
                <b>másik államban</b>– ahol önálló, nem önálló tevékenységéből,
                nyugdíjból és más, hasonló, a korábbi foglalkoztatásból származó
                jövedelme megadóztatható - figyelemmel a kettős adóztatás
                elkerüléséről szóló nemzetközi egyezmények rendelkezéseire is -{" "}
                <b>nem vett és nem is vesz igénybe.</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A külföldi adóügyi illetőségű magánszemély a kedvezményt
                Magyarországon csak akkor érvényesítheti, ha az adóévben
                megszerzett összes jövedelmének – ideértve a Magyarországon nem
                adóztatható jövedelmet is –{" "}
                <b>75 százaléka Magyarországon adózik.</b>
                Az adóévben megszerzett összes jövedelembe beleszámít az önálló
                és nem önálló tevékenységből származó jövedelem – ideértve
                különösen a vállalkozói jövedelmet és a vállalkozói
                osztalékalapot vagy az átalányadó alapját – valamint a nyugdíj
                és más hasonló, a korábbi foglalkoztatásból megszerzett
                jövedelem, függetlenül attól, hogy mely országban adókötelesek.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha Ön{" "}
                <b>
                  külföldi adóügyi illetőségű, a „Kiegészítő nyilatkozat a
                  külföldi adóügyi illetőségű magánszemélyek
                  adóalap-kedvezményének érvényesítéséhez” nyilatkozatot is ki
                  kell töltenie
                </b>{" "}
                és a munkáltató, rendszeres bevételt juttató kifizető részére
                átadnia a kedvezményének igénybevételéhez.
                <b>Adóazonosító jelét</b> ebben az esetben is meg kell adnia.
              </Typography>
              <Box height="2px" />
            </Box>
            <Box height="6px" />
            <Box marginLeft="16px">
              <Box className={classes.smallBgBox}>
                (1.) A súlyos fogyatékosság minősítéséről és igazolásáról a
                49/2009. (XII. 29.) EüM-rendelet rendelkezik.
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                (2.) 2025-ben a havi minimálbér összege 290 800 forint.
              </Box>
            </Box>
          </Box>
          <Box height="16px" />
          <Box className={classes.inlineFlexBoxs}>
            <Box className={classes.bgBox}>
              <Typography variant="h5" className={classes.titles}>
                <b>Az adóelőleg-nyilatkozat kitöltése</b>
              </Typography>
              <Box height="32px" />
              <Typography variant="body1">
                <b>1. pont:</b> a fogyatékos állapot kezdő napját az orvosi
                igazolás tartalma alapján kell a nyilatkozaton feltüntetni. A
                személyi kedvezmény a fogyatékos állapot kezdő napjának
                hónapjában vehető először figyelembe.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b> 2. pont:</b> a fogyatékos állapot utolsó napját akkor kell
                feltüntetni, ha az állapot ideiglenes. A személyi kedvezmény
                utoljára a fogyatékos állapot megszűnésének hónapjában vehető
                figyelembe. Ha ezt a sort nem tölti ki, akkor kifizetője a
                személyi kedvezményt a fogyatékos állapot kezdő hónapjától az
                egész adóévre figyelembe veszi.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>3. pont:</b> itt kell feltüntetni azt, ha az orvosi igazolás
                alapján a fogyatékos állapot végleges.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>4. pont:</b> ha Ön rokkantsági járadékban részesülő
                magánszemélyként nyilatkozik, szerepeltetnie kell a járadékot
                megállapító határozat számát. A rokkantsági járadékra
                jogosultság feltételeit <i>a 83/1987. (XII. 27.) MT rendelet</i>{" "}
                határozza meg.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>5. pont:</b> ha Ön fogyatékossági támogatásban részesül,
                akkor az ezt megállapító határozat számát kell feltüntetnie.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>6. pont:</b> itt kérheti a nyilatkozat folyamatos
                figyelembevételét mindaddig, ameddig nem kéri korábban tett
                nyilatkozatának mellőzését.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>7. pont:</b> itt kérheti, ha a nyilatkozattétel hónapjától
                nem kéri a személy kedvezmény érvényesítését. Ekkor a
                nyilatkozat jobb felső sarkában X-szel kell jelölnie, hogy
                módosított nyilatkozatot nyújt be.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>8. pont:</b> Ebben a pontban kell nyilatkoznia arról, hogy
                azonos vagy hasonló kedvezményt ugyanarra az időszakra másik
                államban nem vett és nem is vesz igénybe.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>9. pont:</b> ezt a részt az Ön munkáltatója, kifizetője tölti
                ki.
              </Typography>
              <Box height="10px" />
            </Box>
            <Box marginLeft="16px">
              <Box className={classes.smallBgBoxWithoutBg}></Box>
            </Box>
          </Box>
          <Box height="16px" />
        </Box>
      </Box>

      <OcPrivateLayoutFooter />
    </Box>
  );
};

export default MyPersonalTaxCreditInformation;
