import gql from "graphql-tag";

export const GET_SPOUSES = gql`
    query spouses($employeeCode: String!) {
        spouses(employeeCode: $employeeCode) {
            contractCode
            name
            taxNumber
            itemSerial
            marriageDate
        }
    }
`;
