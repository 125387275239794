import React from "react";
import { Box } from "@material-ui/core";
import CorporateSignature from "my-hr/components/my-hr-applications-data-panel/components/corporate-signature";
import ApplicationCheckBox from "my-hr/components/my-hr-applications-data-panel/components/application-check-box";
import { DOTTED_LINE } from "my-hr/components/my-hr-applications-data-panel/constants";
import ApplicationPrintCardInfoItem from "my-hr/components/my-hr-applications-data-panel/components/application-print-card-info-item";
import formatTaxNumber from "oc/utils/format-tax-number";

export const MyPersonalTaxCreditApplicationPrint = React.forwardRef(
  (props, ref) => {
    const {
      fullName,
      taxNumber,
      receiveCrippledBenefit,
      defectiveIsPermanent,
      receiveDefectiveSupport,
      crippledDecisionNumber,
      defectiveDecisionNumber,
      defectiveStart,
      defectiveEnd,
      applicationDate,
      employerName,
      employerTaxNumber,
      isModifiedApplication,
      applyToWithdrawal,
      noRequestRelief,
    } = props;
    let year = applicationDate?.slice(0, 4);

    return (
      <Box ref={ref}>
        <Box padding="40px" fontSize="18px" fontFamily="EB Garamond">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ paddingTop: "20px" }}
          >
            <Box
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Adóelőleg-nyilatkozat a személyi kedvezmény érvényesítéséről
              <Box />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginBottom="20px"
              >
                A nyilatkozat benyújtásának éve: {year}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                style={{
                  fontWeight: "normal",
                }}
              >
                A nyilatkozatot adó magánszemély
              </Box>
              <Box
                style={{
                  fontWeight: "normal",
                }}
              >
                <Box display="flex" alignItems="center">
                  Módosított nyilatkozat:
                  <ApplicationCheckBox value={isModifiedApplication} />
                </Box>
              </Box>
            </Box>
            <Box height="15px" />
            <ApplicationPrintCardInfoItem label="neve:" value={fullName} />
            <Box height="15px" />
            <ApplicationPrintCardInfoItem
              label="adóazonosító jele:"
              value={taxNumber}
            />
            <Box height="10px" />
            <Box>
              A bevétel adóelőlegének megállapításához kérem a súlyosan
              fogyatékos magánszemélyek személyi kedvezményének
              figyelembevételét.
            </Box>
            <Box height="10px" />
            <Box
              style={{
                fontWeight: "bold",
              }}
            >
              I. Orvosi igazolás alapján nyilatkozom:
            </Box>
            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              1.
              <Box marginLeft="15px" fontWeight="normal" display="flex">
                A fogyatékos állapot kezdő napja:
                <Box marginLeft="10px">
                  {defectiveStart
                    ? new Date(defectiveStart).toLocaleDateString()
                    : DOTTED_LINE}
                </Box>
              </Box>
            </Box>
            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              2.
              <Box marginLeft="15px" fontWeight="normal" display="flex">
                A fogyatékos állapot végső napja:
                <Box marginLeft="10px">
                  {defectiveEnd
                    ? new Date(defectiveEnd).toLocaleDateString()
                    : DOTTED_LINE}
                </Box>
              </Box>
            </Box>
            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              3.
              <Box marginLeft="15px" fontWeight="normal" display="flex">
                A fogyatékos állapot végleges.
                <ApplicationCheckBox value={defectiveIsPermanent} />
              </Box>
            </Box>
            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              II. Rokkantsági járadékban részesülök.
              <ApplicationCheckBox value={receiveCrippledBenefit} />
            </Box>
            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              4.
              <Box marginLeft="15px" fontWeight="normal" display="flex">
                A rokkantsági járadékot megállapító határozat száma:
                <Box marginLeft="5px">
                  {crippledDecisionNumber
                    ? crippledDecisionNumber
                    : DOTTED_LINE}
                </Box>
              </Box>
            </Box>
            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              III. Fogyatékossági támogatásban részesülök.
              <ApplicationCheckBox value={receiveDefectiveSupport} />
            </Box>
            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              5.
              <Box marginLeft="15px" fontWeight="normal" display="flex">
                A fogyatékossági támogatást megállapító határozat száma:
                <Box marginLeft="5px">
                  {defectiveDecisionNumber
                    ? defectiveDecisionNumber
                    : DOTTED_LINE}
                </Box>
              </Box>
            </Box>
            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              6.
              <Box marginLeft="15px" fontWeight="normal" display="flex">
                Nyilatkozatomat a visszavonásig kérem figyelembe venni
                (folytatólagos nyilatkozatot teszek).
                <Box width="5px" />
                <ApplicationCheckBox value={applyToWithdrawal} />
              </Box>
            </Box>
            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              7.
              <Box marginLeft="15px" fontWeight="normal" display="flex">
                A nyilatkozattétel időpontjától kezdődően (erre a hónapra sem) a
                személyi kedvezményt nem
              </Box>
            </Box>
            <Box marginLeft="30px" fontWeight="normal" display="flex">
              kívánom igénybe venni. <Box width="5px" />
              <ApplicationCheckBox value={noRequestRelief} />
            </Box>

            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              Nyilatkozat a külföldön érvényesíthető kedvezményről
            </Box>
            <Box height="10px" />
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              8.
              <Box marginLeft="15px" fontWeight="normal" display="flex">
                Nyilatkozom, hogy jogosult vagyok a személyi kedvezményt
                Magyarországon érvényesíteni, külföldi államban a jövedelmem
                után azonos vagy hasonló kedvezményt nem veszek (vettem)
                igénybe.
              </Box>
            </Box>
            <Box height="10px" />
            <Box>Kelt: {applicationDate}</Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              marginRight="28px"
            >
              <Box>
                ........................................................
              </Box>
              <Box marginRight="33px">A magánszemély aláírása</Box>
            </Box>
            <Box height="10px" />
            <Box
              style={{
                border: "1px solid black",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "8px",
                paddingBottom: "12px",
                width: "auto",
                height: "auto",
              }}
            >
              <Box
                display="flex"
                style={{
                  fontWeight: "bold",
                }}
              >
                9.
                <Box marginLeft="15px" fontWeight="normal" display="flex">
                  A magánszemély nyilatkozatának tartalmát tudomásul vettem. A
                  magánszemély adóelőlegét a nyilatkozat figyelembevételével
                  állapítom meg.
                </Box>
              </Box>
              <Box height="10px" />
              <Box>
                Az adóelőleget megállapító munkáltató, kifizető megnevezése:
                <Box>{employerName ? employerName : DOTTED_LINE}</Box>
              </Box>
              <Box height="10px" />
              <Box display="flex">
                Az adóelőleget megállapító munkáltató, kifizető adószáma:
                <Box marginLeft="10px">
                  {employerTaxNumber ? formatTaxNumber(employerTaxNumber) : DOTTED_LINE}
                </Box>
              </Box>
              <Box height="10px" />
              <CorporateSignature orientation="horizontal" />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default MyPersonalTaxCreditApplicationPrint;
